import React, { useContext, useState, useEffect, useRef } from 'react';
import { NavLink } from "react-router";
import ReactGA from 'react-ga4';

import ttContext from '../class/AppContext.jsx'
import { RecaptchaVerifier } from 'firebase/auth';

const Account = (props) => {
    const { user } = useContext(ttContext);
    const [data, setData] = useState({ _name: '', _contact: '' });
    // const [disabled, setDisabled] = useState(true);
    const [modal, setModal] = useState(null);

    useEffect(() => {
        if (user) {
            setData({
                _name: (user._info && user._info._name) ? user._info._name : '',
                // _contact: (user.phoneNumber)? '0' + user.phoneNumber.substring(3) : '',
                _stars: (user._info && user._info._stars) ? user._info._stars : null
            });
        }
        // setDisabled(true);
    }, [user]);

    useEffect(() => {
        if (modal) {
            const timer = setTimeout(() => setModal(null), 2000);
            return () => clearTimeout(timer);
        }
    }, [modal])

    // const handleSubmit = (e) => {
    //     e.preventDefault();

    //     backend.updateUserInfo({ _name: data._name })
    //         .then(() => {
    //             setDisabled(true);
    //             setModal('등록되었습니다');
    //         })
    //         .catch(() => setDisabled(true));
    // };

    // const handleChange = (e) => {
    //     let { name, value } = e.target;

    //     setData((vals) => {
    //         vals = { ...vals, [name]: value }
    //         setDisabled(!validateForm(vals));
    //         return vals;
    //     });
    // };

    // const validateForm = (vals) => {
    //     const _nameValid = (vals._name.length >= 2 && vals._name.length < 10);
    //     return _nameValid;
    // }

    return (
        <div className={['account-wrapper', user ? '' : 'centered'].join(' ')}>
            {modal &&
                <div className="modal"><span className="message">{modal}</span></div>
            }
            {!user &&
                <PhoneAuthForm />
            }
            {user &&
                <React.Fragment>
                    <div className="account-form">
                        {/* <div className="row top">
                            <div className="message">티타임 로그인 완료</div>
                        </div> */}
                        {user.phoneNumber &&
                            <div className="row">
                                <div className="col">
                                    <label className="lbl-short">휴대폰 번호</label>
                                    <input type="text" value={user.phoneNumber.replace('+82', '0')} readOnly />
                                </div>
                            </div>
                        }
                        {user.email &&
                            <div className="row">
                                <div className="col">
                                    <label className="lbl-short">이메일</label>
                                    <input type="text" value={user.email} readOnly />
                                </div>
                            </div>
                        }
                        {/* <div className="row">
                            <div className="col">
                                <label className="lbl-short">이름</label>
                                <input type="text" placeholder="이름 또는 별명을 입력하세요" name="_name" value={data._name ? data._name : ''} maxLength="10" onChange={handleChange} required />
                            </div>
                        </div> */}
                        {/* <div className="row">
                            <button className="btn-submit" onClick={handleSubmit} disabled={disabled}>저장</button>
                        </div> */}
                        <div className="row">
                            <div className="col">
                                <label className="lbl-short">관심 골프장</label>
                                {/* <span className="tooltip">골프장 정보 페이지에서 관심 골프장을 등록하세요</span> */}
                                <StarList data={data._stars} keyQuery={props.keyQuery} />
                            </div>
                        </div>
                    </div>
                    <div className="row msg">
                        <AuthButton />
                    </div>
                </React.Fragment >
            }
        </div >
    );
}

const StarList = ({ data, keyQuery }) => {
    const _onClick = (_id) => keyQuery(_id);

    return (
        <ul className="ul-stars ul">
            {data && Object.keys(data).map((k, i) =>
                <li key={data[k].id}>
                    <NavLink to={data[k].id} onClick={() => _onClick(data[k].id)} className="link">
                        <span>{data[k].name}</span>
                        <i className="fas fa-external-link-alt"></i>
                    </NavLink>
                </li>
            )}
            {(data === undefined || (data && data.length === 0)) &&
                <li className="li-empty">등록된 관심 골프장이 없습니다.</li>
            }
        </ul>
    );
}

export const AuthButton = () => {
    const { backend, user /*, setContext*/ } = useContext(ttContext);
    const [isLoading, setLoading] = useState(false);

    const _signOut = () => {
        setLoading(true);
        backend.signOut();

        ReactGA.event({
            category: 'auth',
            action: 'signout'
        });
    }

    React.useEffect(() => {
        setLoading(false);
    }, [user])

    return (
        <React.Fragment>
            {!user &&
                <PhoneAuthForm />
            }
            {user &&
                <button className="btn signout" onClick={_signOut} disabled={isLoading}>
                    {isLoading ? <span className="icon-loading"></span> : <span>로그아웃</span>}
                </button>
            }
        </React.Fragment>
    );
}

export const PhoneAuthForm = () => {
    const [authStage, setAuthStage] = useState(0);

    return (
        <React.Fragment>
            {authStage === 0 && <PhoneAuthForm1 onComplete={() => setAuthStage(1)} />}
            {authStage === 1 && <PhoneAuthForm2 />}
        </React.Fragment>
    );
}

export const PhoneAuthForm1 = (props) => {
    const { onComplete } = props;
    const { backend } = useContext(ttContext);
    const [isLoading, setLoading] = useState(false);
    const [phone, setPhone] = useState('010');
    // const [ forceReset, setForceReset ] = useState(false);
    const [message, setMessage] = useState('휴대폰으로 인증번호를 발송합니다');
    const [method, setMethod] = useState('PHONE');
    // const recaptchaRef = useRef(null);
    const phoneInputRef = useRef(null);

    React.useEffect(() => {
        window.recaptchaVerifier = new RecaptchaVerifier(
            backend.auth,
            "recaptcha-container",
            {
                'size': 'invisible',
                // 'error-callback': () => setForceReset((b) => !b),
                // 'expired-callback': () => setForceReset((b) => !b),
            },
        );

        setLoading(false);
    }, [backend.auth]);

    const _handleChange = (e) => {
        e.preventDefault();
        const value = e.target.value.slice(0, 11);
        setPhone(value);
    }

    const _signIn = (provider) => {
        setLoading(true);
        setMethod(provider);

        ReactGA.event({
            category: 'auth',
            action: 'signin',
            label: provider
        });
    }

    const _signInWithPhone = () => {
        _signIn('PHONE');

        if (!phone || phone.length !== 11 || phone.substring(0, 3) !== '010') {
            phoneInputRef.current.focus();
            return setLoading(false);
        }

        const _phone = '+82' + phone;

        backend.signInWithPhone(_phone, window.recaptchaVerifier)
            .then((success) => {
                if (success)
                    onComplete(); // go to next stage
                else
                    throw new Error();
            })
            .catch(() => {
                setMessage('에러 발생. 잠시 후 다시 시도해주세요.');
                window.recaptchaVerifier?.clear();
                setLoading(false);
                setMethod('PHONE');
            });
    };

    const _signInWithKakao = () => {
        _signIn('KAKAO');

        backend.signInWithKakao()
            .then((res) => {
                if (!res)
                    setLoading(false);
            });
    }

    return (
        <>
            <div className="account-form">
                {isLoading &&
                    <div className="loading-wrap"><div className="icon-loading"></div></div>
                }
                <div className={`row ${(method === 'PHONE') ? '' : 'blurred'}`}>
                    <div className="col">
                        <label className="lbl">휴대폰 번호</label>
                        <input type="number" placeholder="휴대폰 번호를 숫자만 입력하세요" name="_phone" value={phone ? phone : ''} maxLength="11" onChange={_handleChange} ref={phoneInputRef} disabled={isLoading} required />
                    </div>
                </div>
                <div className="row">
                    <span className="message">{message}</span>
                </div>
                <div className={`row ${(method === 'PHONE') ? '' : 'blurred'}`}>
                    <button id="btn-phone-signin" className="btn phone signin" type="submit" /*ref={signInBtnRef}*/ onClick={_signInWithPhone} disabled={isLoading}>
                        {isLoading ? <span>로그인 요청 중...</span> : <span>휴대폰 번호로 로그인</span>}
                    </button>
                </div>
                <div className={`row ${(method === 'KAKAO') ? '' : 'blurred'}`}>
                    <button className="btn kakao signin" type="button" onClick={_signInWithKakao} disabled={isLoading}>
                        {isLoading ? <span>로그인 요청 중...</span> : <span>카카오톡 아이디로 로그인</span>}
                    </button>
                </div>
                <div className="row">
                    <div id="recaptcha-container"></div>
                </div>
            </div>
        </>
    )
}

export const PhoneAuthForm2 = () => {
    const { backend, setContext } = useContext(ttContext);
    const [isLoading, setLoading] = useState(false);
    const [code, setCode] = useState('');
    const [message, setMessage] = useState('휴대폰 문자를 확인해보세요');
    const codeInputRef = useRef(null);

    const _handleChange = (e) => {
        let { value } = e.target;
        setCode(value);
    };

    const _verifyPhoneCode = () => {
        setLoading(true);

        if (!code || code.length < 5) {
            codeInputRef.current.focus();
            setMessage('인증 번호를 입력해주세요');
            setLoading(false);
        }
        else {
            backend.phoneAuthConfirm(code, (u) => setContext(u))
                .then((success) => {
                    if (!success)
                        throw new Error();
                })
                .catch(() => {
                    codeInputRef.current.focus();
                    setMessage('인증 번호를 다시 확인해주세요');
                    setLoading(false);
                });
        }
    }

    return (
        <div className="account-form">
            <div className="row">
                <div className="col">
                    <label className="lbl">인증 번호</label>
                    <input type="number" placeholder="인증 번호를 입력하세요" name="_code" value={code ? code : ''} maxLength="10" onChange={_handleChange} ref={codeInputRef} required />
                </div>
            </div>
            <div className="row">
                <span className="message">{message}</span>
            </div>
            <div className="row">
                <button className="btn phone verify" type="button" onClick={_verifyPhoneCode} disabled={isLoading}>
                    {isLoading ? <span>인증 요청 중...</span> : <span>인증</span>}</button>
            </div>
        </div>
    )
}

export const StarButton = (props) => {
    const { data } = props;
    const { user, backend } = useContext(ttContext);
    const [isStarred, setIsStarred] = useState(false);

    const onStar = () => {
        ReactGA.event({
            category: 'User',
            action: 'onStar',
            label: data._id
        });

        backend.setStar(data)
            .then((starred) => setIsStarred(starred))
            .catch(() => {
                setIsStarred(false);
                props.onClick(true);
            });
    }

    useEffect(() => {
        const _isStarred = user && user._info && user._info._stars && user._info._stars.some((row) => row.id === data._id);
        setIsStarred(_isStarred);
    }, [user, data._id]);

    return (
        <button type="button" className={`btn star ${isStarred}`} onClick={() => onStar()}>
            {!isStarred && <span>즐겨찾기</span>}
            {isStarred && <span>관심 골프장</span>}
        </button>
    );
}

export const NaverButton = (props) => {
    const { backend } = useContext(ttContext);
    const { onClick, disabled } = props;

    useEffect(() => {
        backend.initNaver();
    }, [backend]);

    return (
        <div id="naverIdLogin" className={`${disabled ? 'blurred' : ''}`} onClick={onClick}></div>
    );
}

export const Auth = () => {
    useEffect(() => {
        if (window.opener && window.opener.naver && window.opener.naver.successCallback) {
            window.opener.naver.naverLogin.getLoginStatus((status) => {
                window.opener.naver.successCallback(window);
            });
        }
        else
            window.close();
    }, []);

    return (
        <div className="oauth-wrapper">
            <div className="loading-wrap"><div className="icon-loading"></div></div>
        </div>
    );
}

export default Account;